import React from 'react'

import logo from '../../new-logo.png';
import { Link } from 'react-router-dom';
export default function Navbar() {
    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-light my-nav">

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="logo">
                    <Link to="/" className="navbar-brand"><img src={logo} className="App-logo" alt="logo_naffles" /></Link>
                   
                </div>

                <div className="search-bar">
                    <div className="search">
                        <button type="submit" className="search-button">
                        <i className="fa fa-search"></i>
                        </button>
                    
                    <input type="text" className="search-term" placeholder="Search Naffles, collections, and accounts"/>
                    </div>
                </div>
                <div className="collapse navbar-collapse nft-collapse" id="navbarTogglerDemo03">
                    <ul className="navbar-nav">
                        <li className="nav-item ">
                        <Link to="/explore" className="nav-link1"><b>Explore</b></Link>

                       
                        </li>
                        <li className="nav-item">
                        <Link to="/sell" className="nav-link1"><b>Sell</b></Link>
                        </li>
                        <li className="nav-item">
                        <Link to="/profile"><i className="fas fa-user"></i></Link>
                          
                        </li>
                        <li className="nav-item">
                            <a href="#"><i className="fas fa-wallet"></i></a>
                        </li>
                    </ul>
                </div>


                </nav>
        </header>
    )
}
