import logo from './new_logo.png';
import imageSub from './ImageSub.png';
import nftarte from './nftsarte.png';
import picture1 from './picture1.png';
import picture2 from './picture2.png';
import picture3 from './picture3.png';
import art1 from './art1.jpg';
import icon1 from './icon.png';
import card1 from './firstcard.jpg';
import profilecard from './profileCard.png';
import card2 from './secondcard.jpg';
import card3 from './trhreecard.png';
import walletsvg from './wallet.svg';
import walletnft from './nft.svg';
import salesgv from './sale.svg';
import collectionsvg from './collection.svg';
import verifiedico from './verifiedico.png';

import { Routes, Route, Link } from "react-router-dom";

import Home from './Home';
import Sell from './Sell';
import Explore from './Explore';
import Nft from './Nft';
import Profile from './Profile';

function App() {
  return (
    <div>
       <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sell" element={<Sell />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/nft" element={<Nft />} />
        <Route path="/profile" element={<Profile />} />

      </Routes>
     </div>




  );
}

export default App;
