import { Link } from 'react-router-dom';
import logo from './new_logo.png';
import imageSub from './ImageSub.png';
import nftarte from './nftsarte.png';
import picture1 from './picture1.png';
import picture2 from './picture2.png';
import picture3 from './picture3.png';
import art1 from './art1.jpg';
import icon1 from './icon.png';
import card1 from './firstcard.jpg';
import profilecard from './profileCard.png';
import card2 from './secondcard.jpg';
import card3 from './trhreecard.png';
import walletsvg from './wallet.svg';
import walletnft from './nft.svg';
import salesgv from './sale.svg';
import collectionsvg from './collection.svg';
import verifiedico from './verifiedico.png';
import ethereum from './ethereum.svg';
import activityitem from './activityitem.png';
import nooffers from './nooffers.png';
/* import 'bootstrap/dist/css/bootstrap.min.css';
import './Nft.css'; */
import Navbar from './components/shared/Navbar';
import Footer from './components/shared/Footer';

const Nft = () =>{
  return (
    <div>
      <Navbar />
      <section className="boss-beauties">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="boss-bt">
                  <img src="images/boss-bt.png"/>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="boss-bt">
                  <div className="top-beauties-txt">
                    <a href="#" className="btn small-btn">BOSS BEAUTIES</a>
                    <h1>Boss Beauties #9236</h1>
                  </div>
                  <div className="bottom-beauties-txt">
                    <div className="beauties-cp">
                      <h2>CURRENT PRICE</h2>
                      <h1>$767.08</h1>
                      <a href="#" className="btn">BUY NOW</a>
                    </div>
                    <div className="beauties-cp">
                      <h2>Sale ends May 2, 2022 at</h2>
                      <h1>12:50pm EDT</h1>
                      <div className="beauties-icon">
                        <a href="#"><i className="far fa-square"></i></a>
                        <a href="#"><i className="fas fa-upload"></i></a>
                        <a href="#"><i className="fas fa-share-alt"></i></a>
                        <a href="#"><i className="fas fa-list"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="proerties-sec">
          <div className="container">
            <div className="nft-proerties">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="accordion-wrap">
                    <div className="nft-accordion">
                      <a href="#" className="accordion-btn">DESCRIPTION</a>
                      <div className="accordion-content" id="first-content">
                          <h6>Created by test</h6>
                          <p>A Woman can be everything she wants. For 10 years, it has been our mission to educate and empower the next generation of Women and Girls. The Boss Beauties Collection is an extension of the same passion and golas we have championed for the past decade. This is a collection of 10,000 unique, independent and strong women which are also used to accss exclusive</p>
                      </div>
                    </div>
                    <div className="nft-accordion">
                      <a href="#" className="accordion-btn">PROPERTIES</a>
                      <div className="accordion-content">
                          
                      </div>
                    </div>
                    <div className="nft-accordion">
                      <a href="#" className="accordion-btn">ABOUT BOSS BEAUTIES</a>
                      <div className="accordion-content">
                        
                      </div>
                    </div>
                    <div className="nft-accordion last-child">
                      <a href="#" className="accordion-btn">DETAILS</a>
                      <div className="accordion-content">
                          
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="inner-properties">
                    <div className="list-pp">
                      <h2>PRICE HISTORY</h2>
                      <table>
                        <thead>
                          <tr>
                            <th>SNO.</th>
                            <th>NAME</th>
                            <th>AMOUNT</th>
                            <th>DATE</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1.</td>
                            <td>Product Name</td>
                            <td>15$</td>
                            <td>23/11/2021</td>
                          </tr>
                          <tr>
                            <td>2.</td>
                            <td>Product Name</td>
                            <td>15$</td>
                            <td>23/11/2021</td>
                          </tr>
                          <tr>
                            <td>3.</td>
                            <td>Product Name</td>
                            <td>15$</td>
                            <td>23/11/2021</td>
                          </tr>
                          <tr>
                            <td>4.</td>
                            <td>Product Name</td>
                            <td>15$</td>
                            <td>23/11/2021</td>
                          </tr>
                          <tr>
                            <td>5.</td>
                            <td>Product Name</td>
                            <td>15$</td>
                            <td>23/11/2021</td>
                          </tr>
                          <tr>
                            <td>5.</td>
                            <td>Product Name</td>
                            <td>15$</td>
                            <td>23/11/2021</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="list-pp">
                      <h2>LISTING</h2>
                    </div>
                    <div className="list-pp">
                      <h2>OFFERS</h2>
                      <p>A Woman can be everything she wants. For 10 years, it has been our mission to educate and empower the next generation of Women and Girls.The Boss Beauties Collection is an extension of the same passion and golas we have championed for the past decade. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      <Footer/>
      
    </div>
  );
}

export default Nft;
