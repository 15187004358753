import { Link } from 'react-router-dom';
import logo from './new_logo.png';
import imageSub from './ImageSub.png';
import nftarte from './nftsarte.png';
import picture1 from './picture1.png';
import picture2 from './picture2.png';
import picture3 from './picture3.png';
import art1 from './art1.jpg';
import icon1 from './icon.png';
import card1 from './firstcard.jpg';
import profilecard from './profileCard.png';
import card2 from './secondcard.jpg';
import card3 from './trhreecard.png';
import walletsvg from './wallet.svg';
import walletnft from './nft.svg';
import salesgv from './sale.svg';
import collectionsvg from './collection.svg';
import verifiedico from './verifiedico.png';
import Navbar from './components/shared/Navbar';
import Footer from './components/shared/Footer';
//import './Home.css';


const Home = () =>{
  return (

    <div>
      <Navbar />
      <section className="naffles-banner">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="inner-banner">
              <img src={imageSub} alt="imageSub" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 align-self-center">
              <div className="inner-banner">
                <div className="banner-small-button">
                  <a href="" className="btn small-btn">NAFFLES NFT ART</a>
                </div>
                <h1>ENTER FOR YOUR <br/>CHANCE TO WIN!</h1>
                <h4>Duis aute irure dolor in reprehenderit in voluptate velit esse cilium dolore eu fugiat nulla pariatur at vero eos.</h4>
                <div className="banner-large-button">
                  <a href="" className="btn">EXPLORE</a>
                  <a href="" className="btn">SELL NAFFLES</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="current-naffles">
        <div className="container">
          <h1>CURRENT NAFFLES</h1>
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <div className="current-nft">
                <div className="current-nft-image">
                  <img src={picture1} />
                </div>
                <div className="current-nft-text">
                  <h2>FEELINGS OF THE UNRESOLVED</h2>
                  <h6>YINKORE'S FORM OF THERAPY</h6>
                  <div className="current-nft-button">
                    <a href="#" className="btn">VIEW NAFFALES</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="current-nft">
                <div className="current-nft-image">
                  <img src={picture2} />
                </div>
                <div className="current-nft-text">
                  <h2>FEELINGS OF THE UNRESOLVED</h2>
                  <h6>YINKORE'S FORM OF THERAPY</h6>
                  <div className="current-nft-button">
                    <a href="#" className="btn">VIEW NAFFALES</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="current-nft">
                <div className="current-nft-image">
                  <img src={picture3} />
                </div>
                <div className="current-nft-text">
                  <h2>FEELINGS OF THE UNRESOLVED</h2>
                  <h6>YINKORE'S FORM OF THERAPY</h6>
                  <div className="current-nft-button">
                    <a href="#" className="btn">VIEW NAFFALES</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="about-naffles">
        <div className="container">

      

          <div className="about-part">
            <div className="about-nft-image">
              <img src={art1} />
            </div>
            <div className="about-nft">
              <div className="about-small-button">
                <a href="#" className="btn small-btn">ABOUT US</a>
              </div>
              <h1>Naffles about</h1>
              <h4>Duis aute irure dolor in reprehenderit in voluptate velit esse cilium dolore eu fugiat nulla pariatur at vero eos.</h4>
              <a href="#" className="btn">LEARN MORE</a>
            </div>
          </div>
        </div>
      </section>
      <section className="raffle-system">
        <div className="container">
          <div className="raffle-nft">
            <div className="raffle-top-txt">
              <a href="#" className="btn small-btn">ABOUT US</a>
              <h1>Raffle system</h1>
            </div>
            <h4>Donec quam feliz, pellentesc eu, pretium quis, sem. Nulla conseuqat massaquis enim. Donec pede justo, fringilla vel, aliquet nec, eget, arcu. In enim justo, rhoncus ut, imper diet.</h4>
          </div>
          <div className="raffle-item">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="raffle-st-item">
                  <div className="raffle-st-icon">
                    <img src="images/raffle-icon.png"/>
                  </div>
                  <div className="raffle-st-text">
                    <h4>RAFFLE1</h4>
                    <h6>Maecensa Tempus, tellus eget condi mentum.</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="raffle-st-item">
                  <div className="raffle-st-icon">
                    <img src="images/raffle-icon.png"/>
                  </div>
                  <div className="raffle-st-text">
                    <h4>RAFFLE2</h4>
                    <h6>Maecensa Tempus, tellus eget condi mentum.</h6>
                  </div>
                </div>
              </div>	
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="raffle-st-item">
                  <div className="raffle-st-icon">
                    <img src="images/raffle-icon.png"/>
                  </div>
                  <div className="raffle-st-text">
                    <h4>RAFFLE3</h4>
                    <h6>Maecensa Tempus, tellus eget condi mentum.</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="raffle-st-item">
                  <div className="raffle-st-icon">
                    <img src="images/raffle-icon.png"/>
                  </div>
                  <div className="raffle-st-text">
                    <h4>RAFFLE4</h4>
                    <h6>Maecensa Tempus, tellus eget condi mentum.</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="raffle-st-item">
                  <div className="raffle-st-icon">
                    <img src="images/raffle-icon.png"/>
                  </div>
                  <div className="raffle-st-text">
                    <h4>RAFFLE5</h4>
                    <h6>Maecensa Tempus, tellus eget condi mentum.</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="raffle-st-item">
                  <div className="raffle-st-icon">
                    <img src="images/raffle-icon.png"/>
                  </div>
                  <div className="raffle-st-text">
                    <h4>RAFFLE6</h4>
                    <h6>Maecensa Tempus, tellus eget condi mentum.</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="selling-naffles">
        <div className="container">
          <div className="selling-nft">
            <a href="#" className="btn small-btn">NAFFLES</a>
            <h1>Start Selling Your Naffles Today!</h1>
          </div>
          <div className="selling-items">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-3">
                <div className="sell-item">
                  <img src="images/wallet-img.png"/>
                  <h5>SET UP YOUR WALLET</h5>
                  <p>Once you've set up your wallet of choice, connect it to OpenSea bly clicking the wallet icon in the top right corner. Learn about the wallets we support.</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3">
                <div className="sell-item">
                  <img src="images/collection-img.png"/>
                  <h5>CREATE YOUR COLLECTION</h5>
                  <p>Click My Collection and setup your collection. Add social links, a description, profile, banner images, and set a secondary sales fee.</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3">
                <div className="sell-item">
                  <img src="images/gallery-img.png"/>
                  <h5>ADD YOUR NFTS</h5>
                  <p>Upload your work(image, video, or 3D art), add a title and description, and customize your NFTs with properties, stats and Customize your NFTs with properties, stats and unlockable content.</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3">
                <div className="sell-item">
                  <img src="images/tag-img.png"/>
                  <h5>START YOUR NAFFLE COUNTDOWN</h5>
                  <p>Once you've set up your wallet of choice, connect it to OpenSea bly clicking the wallet icon in the top right corner. Learn about the wallets we support.</p>
                </div>
              </div>
            </div>
            <div className="sell-item-button">
              <a href="#" className="btn">EXPLORE LIVE NAFFLES</a>
            </div>
          </div>
        </div>
      </section>
      
      <Footer />


   


    </div>




  );
}

export default Home;
