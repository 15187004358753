import React from 'react'

export default function Footer() {
    return (
        <footer>
            <div className="inner-footer">
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="nft-footer">
                        <div className="logo">
                            <a className="navbar-brand" href="#">naffles</a>
                        </div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-2">
                    <div className="nft-footer">
                        <h5>EXPLORE NAFFLES</h5>
                        <ul>
                            <li><a href="#"></a>All NFTs</li>
                            <li><a href="#"></a>New</li>
                            <li><a href="#"></a>Art</li>
                            <li><a href="#"></a>Music</li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-2">
                    <div className="nft-footer">
                        <h5>MY ACCOUNT</h5>
                        <ul>
                            <li><a href="#"></a>Profile</li>
                            <li><a href="#"></a>wallet</li>
                            <li><a href="#"></a>Transection History</li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5">
                    <div className="nft-footer">
                        <div className="footer-loop">
                            <div className="ft-loop-text">
                                <h2>STAY IN THE LOOP</h2>
                                <p>Join our mailing list to stay in the loop with our newest feature release and naffles drops.</p>
                            </div>
                            <div className="ft-loop-icon">
                                <a href="#"><i className="fab fa-twitter"></i></a>
                                <a href="#"><i className="fab fa-instagram"></i></a>
                                <a href="#"><i className="fab fa-android"></i></a>
                                <a href="#"><i className="fab fa-whatsapp"></i></a>
                                <a href="#"><i className="fab fa-youtube"></i></a>
                                <a href="#"><i className="far fa-envelope"></i></a>
                            </div>
                        </div>
                        <div className="footer-email">
                            <div className="ft-email-input">
                                <input type="text" placeholder="Your email address"/> 
                            </div>
                            <div className="ft-email-button">
                                <a href="#" className="btn">Sign Up</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div className="footer-bottom">
                <ul>
                <li><a href="#">© Copyright 2017-2021 All Rights Reserved.</a></li>
                    <div className="Terms">
                    <li><a href="#" className="first-child">Privacy Policy</a> </li>
                    <li> <a href="#">Terms of Use</a></li>
                    </div>
                </ul>
            </div>
        </footer>
    )
}
