import { Link } from 'react-router-dom';
import logo from './new_logo.png';
import imageSub from './ImageSub.png';
import nftarte from './nftsarte.png';
import picture1 from './picture1.png';
import picture2 from './picture2.png';
import picture3 from './picture3.png';
import art1 from './art1.jpg';
import icon1 from './icon.png';
import card1 from './firstcard.jpg';
import profilecard from './profileCard.png';
import card2 from './secondcard.jpg';
import card3 from './trhreecard.png';
import walletsvg from './wallet.svg';
import walletnft from './nft.svg';
import salesgv from './sale.svg';
import collectionsvg from './collection.svg';
import verifiedico from './verifiedico.png';
import ethereum from './ethereum.svg';
import Navbar from './components/shared/Navbar';
import Footer from './components/shared/Footer';

const Profile = () =>{
  return (

    <div>
      
      <Navbar />
      <section class="naffles-profile">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-3">
				<div class="nft-profile">
					<div class="prf-image">
						<img src="images/profile-img.png"/>
					</div>
					<div class="prf-data">
						<h2>JXSmith123</h2>
						<h4><a href="mailto:jsmith@gmail.com">jsmith@gmail.com</a></h4>
						<div class="prf-email-button">
							<a href="#" class="btn">naffles129491reffereal.com</a>
						</div>
						<h5><a href="#">refferal link</a></h5>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-9">
				<div class="nft-profile">
					<div class="profile-live-nft">
						<div class="row">
							<div class="col-sm-12 col-md-4">
							  	<div class="user-slider-image">
							  		<img src="images/lion-img.png"/>
							  	</div>
							</div>
							<div class="col-sm-12 col-md-8">
							  	<div class="user-slider">
							  		<div class="user-slide-text">
							  			<h2>Name of Raffel User Is In</h2>
							  			<div class="user-slider-button">
							  				<a href="#" class="btn">VIEW</a>
							  			</div>
							  		</div>
							  	</div>
							</div>
						</div>
					</div>

					<div class="profile-wallet">
						<div class="row">
							<div class="col-sm-12 col-md-4">
								<div class="wallet-item">
								   <div class="user-wallet-img">
								      	<img src="images/boss-bt.png"/>
								   </div>
								   <div class="inner-user-wt">
								      	<div class="user-wallet-text">
								      		<div class="user-wt">
								      			<h3>byteosaurus<br/><span>byteosaurus</span></h3>
								      			<div class="user-wt-img">
									      			<img src="images/user-wallet-img.png"/>
									      		</div>
								      		</div>
								      	</div>
								      	<div class="user-wallet-bottom-txt">
								      		<h3>tickets left<br/><span>73/100</span></h3>
								      	</div>
								    </div>
								</div>
							</div>
							<div class="col-sm-12 col-md-4">
								<div class="wallet-item">
								   <div class="user-wallet-img">
								      	<img src="images/boss-bt.png"/>
								   </div>
								   <div class="inner-user-wt">
								      	<div class="user-wallet-text">
								      		<div class="user-wt">
								      			<h3>byteosaurus<br/><span>byteosaurus</span></h3>
								      			<div class="user-wt-img">
									      			<img src="images/user-wallet-img.png"/>
									      		</div>
								      		</div>
								      	</div>
								      	<div class="user-wallet-bottom-txt">
								      		<h3>tickets left<br/><span>73/100</span></h3>
								      	</div>
								    </div>
								</div>
							</div>
							<div class="col-sm-12 col-md-4">
								<div class="wallet-item">
								   <div class="user-wallet-img">
								      	<img src="images/boss-bt.png"/>
								   </div>
								   <div class="inner-user-wt">
								      	<div class="user-wallet-text">
								      		<div class="user-wt">
								      			<h3>byteosaurus<br/><span>byteosaurus</span></h3>
								      			<div class="user-wt-img">
									      			<img src="images/user-wallet-img.png"/>
									      		</div>
								      		</div>
								      	</div>
								      	<div class="user-wallet-bottom-txt">
								      		<h3>tickets left<br/><span>73/100</span></h3>
								      	</div>
								    </div>
								</div>
							</div>
						</div>
					</div>

					<div class="user-transection">
						<h2>TRANSACTION HISTORY</h2>
						<table>
							<thead>
								<tr>
									<th>PRODUCT NAME</th>
									<th>PRICE</th>
									<th>DATE</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Product Name</td>
									<td>25$</td>
									<td>25/11/2021</td>
								</tr>
								<tr>
									<td>Product Name</td>
									<td>25$</td>
									<td>25/11/2021</td>
								</tr>
								<tr>
									<td>Product Name</td>
									<td>25$</td>
									<td>25/11/2021</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
      <Footer />

    </div>




  );
}

export default Profile;
