import { Link } from 'react-router-dom';
import logo from './new_logo.png';
import coinbase from './coinbase.png';
import metamask from './mestamask.png';
import fortmatic from './fortmatic.png';
import walletto from './walletconnection.jfif';
import Navbar from './components/shared/Navbar';
import Footer from './components/shared/Footer';


import './Sell.css';
const Explore = () =>{
  return (
    <div>
      <Navbar/>


    <div className="row">
      <div className="col-sm-12 text-center">
          <span className="textspanexplore">You need an Ethereum wallet to use Naffles</span>
          <p className="textspanexplore2">Connect with one of our available <font color="#2ebed5">wallet <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
      </svg></font> providers or create a new one.</p>
      </div>
      <div className="col-sm-12">
        <div className="col-sm-3">&nbsp;
        </div>
        <div className="col-sm-6 margin-0">
        <ul class="list-group">
          <li class="list-group-item espacali"><img src={metamask} className="App-ico" alt="logo_naffles" /><span className="fonttitulomask">MetaMask</span></li>
          <li class="list-group-item espacali"><img src={coinbase} className="App-ico" alt="logo_naffles" /><span className="fonttitulomask">Coinbase Wallet</span></li>
          <li class="list-group-item espacali"><img src={walletto} className="App-ico" alt="logo_naffles" /><span className="fonttitulomask">WalletConnect</span></li>
          <li class="list-group-item espacali"><img src={fortmatic} className="App-ico" alt="logo_naffles" /><span className="fonttitulomask">Fortmatic</span></li>
          <li class="list-group-item espacali"><span class="textshowmore">Show more options</span></li>
        </ul>
        </div>
        <div className="col-sm-3">&nbsp;
        </div>
      </div>
    </div>



<Footer/>

    </div>




  );
}

export default Explore;
