import { Link } from 'react-router-dom';
import logo from './new_logo.png';
import imageSub from './ImageSub.png';
import nftarte from './nftsarte.png';
import picture1 from './picture1.png';
import picture2 from './picture2.png';
import picture3 from './picture3.png';
import art1 from './art1.jpg';
import icon1 from './icon.png';
import card1 from './firstcard.jpg';
import profilecard from './profileCard.png';
import card2 from './secondcard.jpg';
import card3 from './trhreecard.png';
import walletsvg from './wallet.svg';
import walletnft from './nft.svg';
import salesgv from './sale.svg';
import collectionsvg from './collection.svg';
import verifiedico from './verifiedico.png';
import ethereum from './ethereum.svg';
import Navbar from './components/shared/Navbar';
import Footer from './components/shared/Footer';
const Explore = () =>{
  return (

    <div>
      
      <Navbar />
      <section className="result-nft-sec">
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <div className="inner-result-nft">
              <div className="top-filter">
                <h4><i className="fas fa-grip-lines"></i>FILTER</h4>
                <i className="fas fa-long-arrow-alt-left"></i>
              </div>
              <div className="accordion" id="result-accordion">
                <div className="card">
                  <div className="card-header" id="heading-one">
                    <h5 className="mb-0">
                      <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">TICKET PRICE</button>
                    </h5>
                  </div>
                  <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#result-accordion">
                    <div className="card-body">
                      <select>
                        <option>UNITED STATE DOLLAR(USD)</option>
                      <option>EURO</option>
                      </select>
                      <div className="card-body-input">
                        <input type="text" placeholder="MIN" />
                        <input type="text" placeholder="MAX" />
                      </div>
                      <div className="card-body-button">
                        <a href="#" className="btn">APPLY</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="heading-two">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">COLLECTIONS
                      </button>
                    </h5>
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body">
                    
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="heading-three">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">CATEG0RIES
                      </button>
                    </h5>
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body">
                    
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="heading-four">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">NUMBER OF TICKET
                      </button>
                    </h5>
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body">
                    
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="heading-five">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">REWARDS VS RISK
                      </button>
                    </h5>
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body">
                    
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="heading-six">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">FLOOR PRICE
                      </button>
                    </h5>
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body">
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-9">
            <div className="beauties-result">
              <div className="beauties-rt-item">
                <p>1,202 results</p>
                <div className="verify-item">
                  <input type="checkbox" id="collection1" name="collection1" />
                    <label for="collection1">Verified Collections Only</label>
                  <select>
                    <option>ALL ITEM</option>
                    <option>ITEM 1</option>
                  </select>
                  <select>
                    <option>SORT BY</option>
                    <option>NAME</option>
                    <option>SIZE</option>
                  </select>
                  <i className="fas fa-th-large"></i>
                  <i className="fas fa-border-none"></i>
                </div>
              </div>
              <div>
                <div className="row">
                   <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="inner-beauties-item">
                          <div className="inner-bt-handimage">
                            <img src="images/item-img.jpg"/>
                          </div>
                          <div className="inner-beauties-item-text">
                            <div className="upper-bt-item">
                              <h2>BYTEOSAURUS <br/><span>BYTEOSAURUS</span></h2>
                              <div className="upper-bt-item-img">
                                <img src="images/favicon.png"/>
                              </div>
                            </div>
                            <div className="middle-bt-item">
                              <h5>68 <br/><span>/230 TICKETS</span></h5>
                              <div>
                                <h6>TICKET PRICE</h6>
                                <div className="inner-beauties-price">
                                  <div className="inner-beauties-price-image">
                                    <img src="images/arow.png"/>
                                  </div>
                                  <h6>0.3</h6>
                                </div>
                              </div>
                            </div>
                            <div className="bottom-bt-item">
                              <h6>5 DAYS REMAINING</h6>
                            </div>
                          </div>	
                        </div>
                   </div>
                   <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="inner-beauties-item">
                          <div className="inner-bt-handimage">
                            <img src="images/item-img.jpg"/>
                          </div>
                          <div className="inner-beauties-item-text">
                            <div className="upper-bt-item">
                              <h2>BYTEOSAURUS <br/><span>BYTEOSAURUS</span></h2>
                              <div className="upper-bt-item-img">
                                <img src="images/favicon.png"/>
                              </div>
                            </div>
                            <div className="middle-bt-item">
                              <h5>68 <br/><span>/230 TICKETS</span></h5>
                              <div>
                                <h6>TICKET PRICE</h6>
                                <div className="inner-beauties-price">
                                  <div className="inner-beauties-price-image">
                                    <img src="images/arow.png"/>
                                  </div>
                                  <h6>0.3</h6>
                                </div>
                              </div>
                            </div>
                            <div className="bottom-bt-item">
                              <h6>5 DAYS REMAINING</h6>
                            </div>
                          </div>	
                        </div>
                   </div>
                   <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="inner-beauties-item">
                          <div className="inner-bt-handimage">
                            <img src="images/item-img.jpg"/>
                          </div>
                          <div className="inner-beauties-item-text">
                            <div className="upper-bt-item">
                              <h2>BYTEOSAURUS <br/><span>BYTEOSAURUS</span></h2>
                              <div className="upper-bt-item-img">
                                <img src="images/favicon.png"/>
                              </div>
                            </div>
                            <div className="middle-bt-item">
                              <h5>68 <br/><span>/230 TICKETS</span></h5>
                              <div>
                                <h6>TICKET PRICE</h6>
                                <div className="inner-beauties-price">
                                  <div className="inner-beauties-price-image">
                                    <img src="images/arow.png"/>
                                  </div>
                                  <h6>0.3</h6>
                                </div>
                              </div>
                            </div>
                            <div className="bottom-bt-item">
                              <h6>5 DAYS REMAINING</h6>
                            </div>
                          </div>	
                        </div>
                   </div>
                   <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="inner-beauties-item">
                          <div className="inner-bt-handimage">
                            <img src="images/item-img.jpg"/>
                          </div>
                          <div className="inner-beauties-item-text">
                            <div className="upper-bt-item">
                              <h2>BYTEOSAURUS <br/><span>BYTEOSAURUS</span></h2>
                              <div className="upper-bt-item-img">
                                <img src="images/favicon.png"/>
                              </div>
                            </div>
                            <div className="middle-bt-item">
                              <h5>68 <br/><span>/230 TICKETS</span></h5>
                              <div>
                                <h6>TICKET PRICE</h6>
                                <div className="inner-beauties-price">
                                  <div className="inner-beauties-price-image">
                                    <img src="images/arow.png"/>
                                  </div>
                                  <h6>0.3</h6>
                                </div>
                              </div>
                            </div>
                            <div className="bottom-bt-item">
                              <h6>5 DAYS REMAINING</h6>
                            </div>
                          </div>	
                        </div>
                   </div>
                   <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="inner-beauties-item">
                          <div className="inner-bt-handimage">
                            <img src="images/item-img.jpg"/>
                          </div>
                          <div className="inner-beauties-item-text">
                            <div className="upper-bt-item">
                              <h2>BYTEOSAURUS <br/><span>BYTEOSAURUS</span></h2>
                              <div className="upper-bt-item-img">
                                <img src="images/favicon.png"/>
                              </div>
                            </div>
                            <div className="middle-bt-item">
                              <h5>68 <br/><span>/230 TICKETS</span></h5>
                              <div>
                                <h6>TICKET PRICE</h6>
                                <div className="inner-beauties-price">
                                  <div className="inner-beauties-price-image">
                                    <img src="images/arow.png"/>
                                  </div>
                                  <h6>0.3</h6>
                                </div>
                              </div>
                            </div>
                            <div className="bottom-bt-item">
                              <h6>5 DAYS REMAINING</h6>
                            </div>
                          </div>	
                        </div>
                   </div>
                   <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="inner-beauties-item">
                          <div className="inner-bt-handimage">
                            <img src="images/item-img.jpg"/>
                          </div>
                          <div className="inner-beauties-item-text">
                            <div className="upper-bt-item">
                              <h2>BYTEOSAURUS <br/><span>BYTEOSAURUS</span></h2>
                              <div className="upper-bt-item-img">
                                <img src="images/favicon.png"/>
                              </div>
                            </div>
                            <div className="middle-bt-item">
                              <h5>68 <br/><span>/230 TICKETS</span></h5>
                              <div>
                                <h6>TICKET PRICE</h6>
                                <div className="inner-beauties-price">
                                  <div className="inner-beauties-price-image">
                                    <img src="images/arow.png"/>
                                  </div>
                                  <h6>0.3</h6>
                                </div>
                              </div>
                            </div>
                            <div className="bottom-bt-item">
                              <h6>5 DAYS REMAINING</h6>
                            </div>
                          </div>	
                        </div>
                   </div>
                   <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="inner-beauties-item">
                          <div className="inner-bt-handimage">
                            <img src="images/item-img.jpg"/>
                          </div>
                          <div className="inner-beauties-item-text">
                            <div className="upper-bt-item">
                              <h2>BYTEOSAURUS <br/><span>BYTEOSAURUS</span></h2>
                              <div className="upper-bt-item-img">
                                <img src="images/favicon.png"/>
                              </div>
                            </div>
                            <div className="middle-bt-item">
                              <h5>68 <br/><span>/230 TICKETS</span></h5>
                              <div>
                                <h6>TICKET PRICE</h6>
                                <div className="inner-beauties-price">
                                  <div className="inner-beauties-price-image">
                                    <img src="images/arow.png"/>
                                  </div>
                                  <h6>0.3</h6>
                                </div>
                              </div>
                            </div>
                            <div className="bottom-bt-item">
                              <h6>5 DAYS REMAINING</h6>
                            </div>
                          </div>	
                        </div>
                   </div>
                   <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="inner-beauties-item">
                          <div className="inner-bt-handimage">
                            <img src="images/item-img.jpg"/>
                          </div>
                          <div className="inner-beauties-item-text">
                            <div className="upper-bt-item">
                              <h2>BYTEOSAURUS <br/><span>BYTEOSAURUS</span></h2>
                              <div className="upper-bt-item-img">
                                <img src="images/favicon.png"/>
                              </div>
                            </div>
                            <div className="middle-bt-item">
                              <h5>68 <br/><span>/230 TICKETS</span></h5>
                              <div>
                                <h6>TICKET PRICE</h6>
                                <div className="inner-beauties-price">
                                  <div className="inner-beauties-price-image">
                                    <img src="images/arow.png"/>
                                  </div>
                                  <h6>0.3</h6>
                                </div>
                              </div>
                            </div>
                            <div className="bottom-bt-item">
                              <h6>5 DAYS REMAINING</h6>
                            </div>
                          </div>	
                        </div>
                   </div>
                   <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="inner-beauties-item">
                          <div className="inner-bt-handimage">
                            <img src="images/item-img.jpg"/>
                          </div>
                          <div className="inner-beauties-item-text">
                            <div className="upper-bt-item">
                              <h2>BYTEOSAURUS <br/><span>BYTEOSAURUS</span></h2>
                              <div className="upper-bt-item-img">
                                <img src="images/favicon.png"/>
                              </div>
                            </div>
                            <div className="middle-bt-item">
                              <h5>68 <br/><span>/230 TICKETS</span></h5>
                              <div>
                                <h6>TICKET PRICE</h6>
                                <div className="inner-beauties-price">
                                  <div className="inner-beauties-price-image">
                                    <img src="images/arow.png"/>
                                  </div>
                                  <h6>0.3</h6>
                                </div>
                              </div>
                            </div>
                            <div className="bottom-bt-item">
                              <h6>5 DAYS REMAINING</h6>
                            </div>
                          </div>	
                        </div>
                   </div>
                   <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="inner-beauties-item">
                          <div className="inner-bt-handimage">
                            <img src="images/item-img.jpg"/>
                          </div>
                          <div className="inner-beauties-item-text">
                            <div className="upper-bt-item">
                              <h2>BYTEOSAURUS <br/><span>BYTEOSAURUS</span></h2>
                              <div className="upper-bt-item-img">
                                <img src="images/favicon.png"/>
                              </div>
                            </div>
                            <div className="middle-bt-item">
                              <h5>68 <br/><span>/230 TICKETS</span></h5>
                              <div>
                                <h6>TICKET PRICE</h6>
                                <div className="inner-beauties-price">
                                  <div className="inner-beauties-price-image">
                                    <img src="images/arow.png"/>
                                  </div>
                                  <h6>0.3</h6>
                                </div>
                              </div>
                            </div>
                            <div className="bottom-bt-item">
                              <h6>5 DAYS REMAINING</h6>
                            </div>
                          </div>	
                        </div>
                   </div>
                   <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="inner-beauties-item">
                          <div className="inner-bt-handimage">
                            <img src="images/item-img.jpg"/>
                          </div>
                          <div className="inner-beauties-item-text">
                            <div className="upper-bt-item">
                              <h2>BYTEOSAURUS <br/><span>BYTEOSAURUS</span></h2>
                              <div className="upper-bt-item-img">
                                <img src="images/favicon.png"/>
                              </div>
                            </div>
                            <div className="middle-bt-item">
                              <h5>68 <br/><span>/230 TICKETS</span></h5>
                              <div>
                                <h6>TICKET PRICE</h6>
                                <div className="inner-beauties-price">
                                  <div className="inner-beauties-price-image">
                                    <img src="images/arow.png"/>
                                  </div>
                                  <h6>0.3</h6>
                                </div>
                              </div>
                            </div>
                            <div className="bottom-bt-item">
                              <h6>5 DAYS REMAINING</h6>
                            </div>
                          </div>	
                        </div>
                   </div>
                   <div className="col-sm-12 col-md-6 col-lg-3">
                        <div className="inner-beauties-item">
                          <div className="inner-bt-handimage">
                            <img src="images/item-img.jpg"/>
                          </div>
                          <div className="inner-beauties-item-text">
                            <div className="upper-bt-item">
                              <h2>BYTEOSAURUS <br/><span>BYTEOSAURUS</span></h2>
                              <div className="upper-bt-item-img">
                                <img src="images/favicon.png"/>
                              </div>
                            </div>
                            <div className="middle-bt-item">
                              <h5>68 <br/><span>/230 TICKETS</span></h5>
                              <div>
                                <h6>TICKET PRICE</h6>
                                <div className="inner-beauties-price">
                                  <div className="inner-beauties-price-image">
                                    <img src="images/arow.png"/>
                                  </div>
                                  <h6>0.3</h6>
                                </div>
                              </div>
                            </div>
                            <div className="bottom-bt-item">
                              <h6>5 DAYS REMAINING</h6>
                            </div>
                          </div>	
                        </div>
                   </div>
                    
                  </div>
              </div>
            </div>
          </div>
          

         
        </div>
      </section>
    <Footer />

    </div>




  );
}

export default Explore;
